import classes from "./section_two.module.css";
import dollarIcon from "./../../../../../icons/dollar-square.svg";
import calendarIcon from "./../../../../../icons/calendar.svg";
import fieldIcon from "./../../../../../icons/field.svg";
import HeroImg from "../../../../../assets/pictures/home/hero.png";
import ButtonRegister from "../../../../../Components/button_register/button_register";
const detailsData = [
  {
    id: "1",
    icon: calendarIcon,
    key: "Qualifikation:",
    value: "1. November",
  },
  {
    id: "2",
    icon: fieldIcon,
    key: "Sportsplatz:",
    value: "Westside Soccer Arena",
  },
  { id: "3", icon: dollarIcon, key: "Startgeld:", value: "€ 100" },
];

const SectionTwo = () => {
  // const [playsDate, setPlaysDate] = useState<any[]>(initialPlaysDate);

  // useEffect(() => {
  //   axios
  //     .get("https://api.goldensports.at/request/sommercup_sports_fields")
  //     .then((res) => {
  //       // console.log(res.data.data.rows);
  //       setPlaysDate(res.data.data.rows);
  //       // setFinalMatch(res.data.data.rows.find((item) => item.final == 1));
  //     });
  // }, []);

  return (
    <div className={classes.section_two_wrapper}>
      <div className={classes.title}>
        <h1>Turniererklärung</h1>
      </div>
      <div className={classes.section_two}>
        <div className={classes.container}>
          {/* <div className={classes.title}>
            <h1>Turniererklärung</h1>
          </div> */}
          <div className={classes.explain}>
            <p>
              Gespielt wird innerhalb der Kontinente. Mannschaften aus Asien
              oder Europa spielen in einem Kontinentalbewerb gegeneinander. Die
              Gewinner jeder Kontinentalrunde treten am Ende gegeneinander an um
              den Gesamtsieger zu ermitteln. Gespielt wird in vier
              Kontinentalbereiche Europa, Amerika, Asien und Afrika. Ozeanien
              spielt in der Asien Gruppe.
            </p>
          </div>
          <div className={classes.plays_date}>
            {detailsData.map((item) => (
              <div className={classes.item} key={item.id}>
                <span className={classes.image}>
                  <img src={item.icon} />
                </span>
                <span className={classes.key}>{item.key}</span>
                <span className={classes.value}>{item.value}</span>
              </div>
            ))}
          </div>
          <ButtonRegister />
        </div>
        <div className={classes.items_right}>
          <div className={classes.image}>
            <img src={HeroImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
