// import Layout from "./Components/Layout"
import { Routes, Route } from "react-router-dom";
// import Layout from "./Components/Layout";
import CommingSoon from "./pages/coming-soon/comingSoon";

import Register from "./pages/KontinentalCup/register/register";
import { Turnierregeln } from "./pages/KontinentalCup/turnierregeln/turnierregeln";
import LayoutKontinentalCup from "./Components/layout-kontinentalCup/layoutKontinentalCup";
// import { SportsPlatz } from "./pages/KontinentalCup/sports-platz/sportsPlatz";
import { Home } from "./pages/KontinentalCup/home/home";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutKontinentalCup />}>
        <Route index element={<Home />} />
        <Route path="register" element={<Register />} />
        <Route path="coming-soon" element={<CommingSoon />} />
        {/* <Route path="sports-platz" element={<SportsPlatz />} /> */}
        <Route path="turnierregeln" element={<Turnierregeln />} />
      </Route>
    </Routes>
  );
};

export default Router;
