import { useEffect, useState } from "react";
import classes from "./teams_item.module.css";
import { RegisteredTeam } from "../../types/types";

const TeamsItem: React.FC<{ teams: RegisteredTeam[] }> = ({ teams }) => {
  return (
    <div className={classes.teams_wrapper}>
      <div className={classes.teams}>
        {teams.map((item) => (
          <>
            <div className={classes.teamsWrapper}>
              <div className={classes.title}>
                <h3>{item.name}</h3>
              </div>

              <div className={classes.items}>
                <div className={classes.item} key={item.id}>
                  {item.teams.map((item) => (
                    <>
                      <div className={classes.item} key={item.id}>
                        <div className={classes.flag}>
                          <img src={item.flag} alt="" />
                        </div>
                        <p>{item.name}</p>
                        <span className={classes.city_name}>
                          ({item.country})
                        </span>
                      </div>
                      {/* <p>{item.name}</p>
                      <span className={classes.city_name}>
                        ({item.name} <div className={classes.flag}></div>)
                      </span> */}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
export default TeamsItem;
