import TeamsItem from "../../../../../Components/teams_item/teams_item";
import { RegisteredTeam } from "../../../../../types/types";
import classes from "./section_five.module.css";

//flags
import tshFlag from "./../../../../../assets/pictures/home/flags/tsh.png"
import albFlag from "./../../../../../assets/pictures/home/flags/alb.png"
import autFlag from "./../../../../../assets/pictures/home/flags/aut.png"
import turFlag from "./../../../../../assets/pictures/home/flags/tur.png"
import kroFlag from "./../../../../../assets/pictures/home/flags/kro.png"
import belFlag from "./../../../../../assets/pictures/home/flags/bel.png"
import irnFlag from "./../../../../../assets/pictures/home/flags/irn.png"
// const registeredTeams: any[] = [
//   { id: "1", title: "Golden Boys", flag: "", country: "Aus" },
//   { id: "2", title: "Golden Boys", flag: "", country: "Aus" },
//   { id: "3", title: "Golden Boys", flag: "", country: "Aus" },
//   { id: "4", title: "Golden Boys", flag: "", country: "Aus" },
//   { id: "5", title: "Golden Boys", flag: "", country: "Aus" },
//   { id: "6", title: "Golden Boys", flag: "", country: "Aus" },
//   { id: "7", title: "Golden Boys", flag: "", country: "Aus" },
// ];

const continentTeams: RegisteredTeam[] = [
  {
    id: "1",
    name: "Europe",
    teams: [
      { id: "1", name: "Rumänien", flag: tshFlag, country: "Tsh" },
      { id: "2", name: "Albanien", flag: albFlag, country: "Alb" },
      { id: "3", name: "Österreich", flag: autFlag, country: "Aut" },
      { id: "4", name: "Türkei", flag: turFlag, country: "Tur" },
      { id: "5", name: "Kroatien", flag: kroFlag, country: "Kro" },
      { id: "6", name: "Belgien", flag: belFlag, country: "Bel" },
    ],
  },
  {
    id: "2",
    name: "America",
    teams: [
    ],
  },
  {
    id: "3",
    name: "Africa",
    teams: [
    ],
  },
  {
    id: "4",
    name: "Asia",
    teams: [
      { id: "1", name: "Iran", flag: irnFlag, country: "Irn" },
    ],
  },
  // {
  //   id: "5",
  //   name: "Oceania",
  //   teams: [{ id: "1", name: "team 1", flag: "", country: "Aus" }],
  // },
];

const SectionFive: React.FC = () => {
  return (
    <div className={classes.section_five_wrapper}>
      <div className={classes.section_five}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>Teams</h1>
          </div>
          <div className={classes.items}>
            <TeamsItem teams={continentTeams} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
